.image {
    border-radius: 50%;
}

.text-container {
    max-width: 575px;
}

.title {
    font-size: 28px;
    font-weight: normal;
    margin-bottom: 11px;
}

.description {
    font-size: 18px;
    line-height: 1.8em;
}

.about-button {
    width: 136px;
    height: 42px;
    color: white;
    transition: all 0.2s ease, visibility 0s;
    background: #5E9261;
    font-size: 15px;
    letter-spacing: 1.5px;
    font-family: Arial,Helvetica,sans-serif;
    border: none;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
}

.about-button:hover {
    background: #8BB68E;
    color: white;
}
