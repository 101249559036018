.about-intro {
    margin-bottom: 50px;
    max-width: 980px;
}

.about-intro-title {
    color: rgb(16, 55, 18);
    font-size: 40px;
}

.about-intro-text {
    font-size: 18px;
    line-height: 1.875em;
}

.resume-intro {
    margin-bottom: 24px;
}

.resume-title {
    color: rgb(16, 55, 18);
    font-size: 40px;
    margin-bottom: 12px;
}

.resume-subtitle {
    color: rgb(16, 55, 18);
    font-size: 18px;
    text-align: center;
}

.experience-grid {
    column-gap: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: space-evenly;
}

.experience-block {
    background-color: rgb(139, 182, 142);
    box-sizing: border-box;
    padding: 36px;
}

.experience-title {
    color: white;
    font-size: 22px;
    font-weight: bold;
    line-height: 31px;
    margin-bottom: 8px;
    text-align: center;
}

.experience-subtitle {
    font-size: 18px;
    margin-bottom: 44px;
    text-align: center;
}

.experience ul {
    font-size: 15px;
    line-height: 28px;
    text-align: left;
}

