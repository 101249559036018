* {
    font-family: Helvetica, Sans-Serif, sans-serif;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 700ms cubic-bezier(.22, 1, .33, 1);
}
.fade-exit {
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 700ms cubic-bezier(.22, 1, .33, 1);
}

/* Nav styles */
.link,
.link:visited,
.link:hover,
.nav-link,
.nav-link:visited,
.nav-link:hover,
.nav-link:focus {
    color: black;
    text-decoration: none;
}

.link.active,
.navbar-nav .nav-link.active {
    color: rgb(117, 117, 117);
}

.dropdown-item.active,
.dropdown-item:focus {
    background-color: white;
}

/* page styles */

.page-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 83px;
}
