.project-grid {
    column-gap: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: space-evenly;
}

.project-block {
    box-sizing: border-box;
    padding: 36px;
}

.project-title {
    color: rgb(94, 146, 97);
    display: block;
    font-size: 22px;
    margin-bottom: 60px;
    text-align: center;
}

.project-title:hover {
    color: #8BB68E;
}

.project-description {
    font-family: avenir-lt-w01_35-light1475496, avenir-lt-w05_35-light, sans-serif;
    font-size: 19px;
}
